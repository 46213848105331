<template>
  <div id="Navbar" :class="!collapse ? '' : 'r-move-navbar'">
    <div :class="!collapse ? '' : 'r-hidesidebar'" @click="setCollapse()"></div>
    <div class="r-humburger-clone" @click="setCollapse()" :class="!collapse ? '' : 'r-move-burger'">
        <img :src="require('@/assets/icons/sidebar/menu.svg')">
    </div>
    <div class="r-profile-space r-center-flex">
      <div class="r-backarrow" @click="back()" v-if="breadcrumb.length > 1">
        <img :src="require('@/assets/icons/others/keyboard_backspace.svg')" alt="">
      </div>
      <div class="r-center-flex" style="margin-left: auto;">
        <div class="r-menu r-center-flex" style="margin-left: 5px;">
            <img style="margin-left: auto;cursor: pointer" :src="require('@/assets/icons/navbar/user-circle.svg')" @click.prevent="$router.push({name: 'profile'})">
        </div>
      </div>
    </div>
    <div class="r-breadcrumb-space r-center-flex">
      <div class="r-breadcrumb-items r-center-flex">
        <div class="r-breadcrumb-item r-center-flex" v-for="(br, index) in breadcrumb" :key="`br-${index}`">
          <img :src="require('@/assets/icons/navbar/breadcrumb-next.svg')" v-if="index != 0">
          <p :class="br.current ? 'y-orange-text y-breadcrumb-active' : 'r-is-clickable'" @click="goto(br.link, br.current)">{{br.name}}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data:()=>({
    popup: false,
    changeAccount: false
  }),
  async mounted(){
    await this.$store.restored
  },
  methods: {
    async changeUser(item){
      if(item == this.username) return
      await this.$store.dispatch('auth/changeAccount',item)
    },
    goto(link, current) {
      if(!current){
        this.$router.push(link);
      }
    },
    back(){
      if(this.breadcrumb.length > 1){
        var data = this.breadcrumb[this.breadcrumb.length - 2]
        this.goto(data.link)
      }
    },
    setCollapse(){
      this.$store.commit('SET_COLLAPSE')
    }
  },
  computed: {
    ...mapGetters({
      activeTab: 'getActiveTab',
      role: 'auth/getRole',
      breadcrumb: 'getBreadcrumb',
      collapse: 'getCollapse',
      username: 'auth/getUsername'
    }),
  },
  watch:{

  }
};
</script>
<style lang="scss">
.r-move-navbar{
  width: 100% !important;
}
.r-move-burger{
  left: 0px !important;
}
#Navbar{
  transition: all 0.2s ease;
  background: white;
  width: calc(100% - 230px);
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 1000;
  .r-backarrow{
    background: #0000000d;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .r-profile-space{
    height: 38px;
    padding: 0px 24px;
    box-shadow: inset 0px -1px 0px #E6E6E6;
  }
  .r-breadcrumb-space{
    box-shadow: inset 0px -1px 0px #E6E6E6;
    height: 42px;
    padding: 0px 20px;
    .r-breadcrumb-items{
      color: #747474;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 13px;
      .r-breadcrumb-item{
        img{
          margin-right: 10px;
        }
        p{
          margin-right: 5px;
        }
        .y-breadcrumb-active{
          font-weight: 600;
          font-size: 13px;
        }
      }
    }
  }
}
.r-humburger-clone{
  position: absolute;
  z-index: 10000000;
  background: #565ECB;
  height: 30px;
  display: flex;
  align-items: center;
  width: 30px;
  justify-content: center;
  border-radius: 0px 8px 8px 0px;
  top: 22px;
  cursor: pointer;
  transition: all 0.2s ease;
}
</style>
